import { JobHealthBaseFragment } from '@upper/graphql/internal'
import { classNames, Text } from '@upper/ui'
import { formatDate, fullPercentageForValue } from '@upper/utils'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { jobHealthColorForValue } from '../../utils/job'

type JobHealthInfoProps = Omit<JobHealthBaseFragment, '__typename'>
export const JobHealthInfo = forwardRef<any, JobHealthInfoProps>(
  (
    {
      percentage,
      performance,
      daysToStaffingDeadline,
      daysToFirstCandidateProposed,
      stage1Count,
      firstCandidateProposedDate,
      stage1TalentsInVettingCount,
      stage1Threshold,
      stage2Count,
      stage2Threshold,
      stage3Count,
      stage3Threshold,
      stage4Count,
      stage4Threshold,
      deadlineInDays,
    },
    ref
  ) => {
    const fullPercentage = fullPercentageForValue(percentage)
    const colors = jobHealthColorForValue(performance)

    return (
      <div ref={ref} className="text-gray-dark min-w-[200px] px-2">
        <header className="border-gray-light flex items-center justify-between py-1">
          <Text variant="subtitle" className="text-gray-darkest">
            Job Health
          </Text>
          <div
            ref={ref}
            className={classNames(
              'inline-flex h-7 w-7 items-center justify-center rounded-md font-mono'
            )}
            style={{ color: colors.background }}
          >{`${fullPercentage}%`}</div>
        </header>
        <div className="space-y-1 py-1 text-sm">
          <InfoRow
            label="Invited"
            value={`${stage1Count ?? 0}/${stage1Threshold ?? 0}`}
            caption={`Of which ${stage1TalentsInVettingCount ?? 0} in vetting`}
          />
          <InfoRow
            label="Matching"
            value={`${stage2Count ?? 0}/${stage2Threshold ?? 0}`}
          />
          <InfoRow
            label="Proposed"
            value={`${stage3Count ?? 0}/${stage3Threshold ?? 0}`}
          />
          <InfoRow
            label="Interview"
            value={`${stage4Count ?? 0}/${stage4Threshold ?? 0}`}
          />
          <div className="flex flex-col items-center justify-between py-2">
            <Text variant="subtitle" className="text-xs text-slate-500">
              {firstCandidateProposedDate ? (
                <Text variant="subtitle" className="text-slate-500">
                  First proposal on{' '}
                  <strong className="font-medium">
                    {formatDate(firstCandidateProposedDate)}
                  </strong>
                </Text>
              ) : (
                'Countdown to first proposal'
              )}
            </Text>
            <Text
              variant="title"
              className={twMerge(
                'font-bold',
                (parseFloat(daysToStaffingDeadline.toString()) < 0 ||
                  daysToFirstCandidateProposed > deadlineInDays) &&
                  'text-red-600'
              )}
            >
              {!firstCandidateProposedDate
                ? `${daysToStaffingDeadline} days`
                : `In ${daysToFirstCandidateProposed} days`}
            </Text>
            <Text variant="subtitle" className="text-xs text-slate-500">
              Deadline: {deadlineInDays} days
            </Text>
          </div>
        </div>
      </div>
    )
  }
)
JobHealthInfo.displayName = 'JobHealthInfo'

type InfoRowProps = { label: string; value: string; caption?: string }
const InfoRow = ({ label, value, caption }: InfoRowProps) => {
  return (
    <div className="-mx-2 grid grid-cols-2 items-center justify-between rounded-md bg-slate-100 px-2 py-px">
      <p className="text-slate-500">{label}</p>
      <p className="font-mono-chivo flex flex-col items-end justify-end">
        {value}
      </p>
      {caption && (
        <span className="text-gray col-span-2 w-full text-xs font-light">
          {caption}
        </span>
      )}
    </div>
  )
}
