import { UserDetailsFragment } from '@upper/graphql/internal'
import { PlusIcon } from '@upper/icons'
import { AvatarProps, classNames, Tooltip } from '@upper/ui'
import { formatName, formatNameInitials } from '@upper/utils'
import { forwardRef } from 'react'

type TeamActionProps = {
  label: string
  user?: UserDetailsFragment
  size?: AvatarProps['size']
}

export const TeamMember = forwardRef<any, TeamActionProps>(
  ({ label, user, size = 'xs' }, ref) => {
    return (
      <>
        <Tooltip
          key={user?.id}
          content={
            <span className="text-xs font-normal tracking-wide">
              {user ? `${label}: ${formatName(user)}` : `Add ${label}`}
            </span>
          }
          triggerProps={{ asChild: true }}
        >
          {user ? (
            <div
              className={classNames(
                'rounded text-xs w-8 h-8',
                user ? 'bg-gray-dark' : 'bg-gray',
                'overflow-hidden text-white tracking-wide font-mono group flex-none'
              )}
            >
              {user?.profilePicUrl ? (
                // eslint-disable-next-line @next/next/no-img-element
                <img
                  src={user?.profilePicUrl}
                  alt={formatNameInitials(user)}
                  className="object-cover w-full h-full overflow-hidden text-xs font-light"
                />
              ) : (
                <div className="flex items-center justify-center w-full h-full text-center">
                  {formatNameInitials(user)}
                </div>
              )}
            </div>
          ) : (
            <span className="block text-blue bg-white p-2.5 rounded border border-gray-light">
              <PlusIcon width={12} height={12} />
            </span>
          )}
        </Tooltip>
      </>
    )
  }
)

TeamMember.displayName = 'TeamMember'
