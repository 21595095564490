/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  JobInterviewer,
  JobInterviewerInput,
  JobSourcer,
  JobSourcerInput,
  JobUpdateDocument,
  RoleKey,
  UserDetailsFragment,
} from '@upper/graphql/internal'
import { AvatarProps, classNames, Spinner } from '@upper/ui'
import { pick } from '@upper/utils'
import { memo, useCallback, useEffect, useState } from 'react'
import { useMutation } from 'urql'
import { TeamMember } from '../team-member'
import { UserPicker } from '../user-picker'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SourcerRelation = any & {
  sourcerId: string
  order?: number
  isLead?: boolean
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InterviewerRelation = any & {
  interviewerId: string
  order?: number
}
type Props = {
  size?: AvatarProps['size']
  jobId?: string
  team?: {
    em?: UserDetailsFragment
    expert?: UserDetailsFragment
    fellow?: UserDetailsFragment
    jobSourcers?: JobSourcer[]
    jobInterviewers?: JobInterviewer[]
  }
  className?: string
}
export const JobTeam = memo(
  ({ size = 'xs', jobId, team, className }: Props) => {
    const [updatedJob, updateJob] = useMutation(JobUpdateDocument)

    const [loading, setLoading] = useState(false)

    const handleSelectUser = useCallback(
      (
        key: keyof Pick<Props['team'], 'em' | 'expert' | 'fellow'>,
        value: UserDetailsFragment
      ) => {
        setLoading(true)
        updateJob({ id: jobId, data: { [key]: value } })
      },
      [jobId, updateJob]
    )

    const handleSourcerSelect = useCallback(
      async (value?: JobSourcer['sourcer'] | null, index = 0) => {
        const jobSourcers: JobSourcerInput[] = (team?.jobSourcers ?? []).map(
          (js) => {
            return { ...pick(js, 'sourcerId', 'isLead', 'order'), jobId }
          }
        )

        if (jobSourcers.length === 0) {
          index = 0
        }
        if (value && Object.keys(value).length === 0) {
          jobSourcers.splice(index, 1)
        } else {
          jobSourcers[index] = {
            sourcerId: value?.id,
            order: index + 1,
          }
        }

        if (jobSourcers[0]) jobSourcers[0].isLead = true

        setLoading(true)
        updateJob({ id: jobId, data: { jobSourcers } })
      },
      [jobId, team?.jobSourcers, updateJob]
    )

    const handleInterviewerSelect = useCallback(
      async (value?: JobInterviewer['interviewer'], index = 0) => {
        const jobInterviewers: JobInterviewerInput[] = (
          team.jobInterviewers ?? []
        ).map((js) => {
          return pick(js, 'interviewerId', 'order')
        })
        if (jobInterviewers.length === 0) {
          index = 0
        }
        if (value && Object.keys(value).length === 0) {
          jobInterviewers.splice(index, 1)
        } else {
          jobInterviewers[index] = {
            interviewerId: value?.id,
            order: index + 1,
          }
        }

        setLoading(true)
        updateJob({ id: jobId, data: { jobInterviewers } })
      },
      [jobId, team.jobInterviewers, updateJob]
    )

    const excludedInterviewerIds =
      team?.jobInterviewers?.map((ji) => ji.interviewerId) ?? []

    const excludedSourcerIds =
      team?.jobSourcers?.map((ji) => ji.sourcerId) ?? []

    useEffect(() => {
      setLoading(false)
    }, [team])

    const displaySpinner = loading || updatedJob.fetching

    return (
      <div
        className={classNames(
          'relative flex flex-shrink-0 grid-cols-7 gap-1',
          className
        )}
      >
        {displaySpinner && (
          <div className="absolute grid h-full w-full place-items-center rounded-md bg-white/80">
            <Spinner className="text-blue !h-6 !w-6" />
          </div>
        )}
        {/* em */}
        <UserPicker
          roles={[RoleKey.Em]}
          user={team?.em}
          onChange={(v) => handleSelectUser('em', v)}
        >
          <TeamMember size={size} label="EM" user={team?.em} />
        </UserPicker>
        {/* sourcer1 */}
        <UserPicker
          roles={[RoleKey.Sourcer, RoleKey.ExternalSourcer]}
          user={team?.jobSourcers && team?.jobSourcers[0]?.sourcer}
          onChange={(v) => handleSourcerSelect(v, 0)}
          exclude={excludedSourcerIds}
        >
          <TeamMember
            size={size}
            label="Sourcer 1"
            user={team?.jobSourcers?.[0]?.sourcer}
          />
        </UserPicker>
        {/* sourcer2 */}
        <UserPicker
          roles={[RoleKey.Sourcer, RoleKey.ExternalSourcer]}
          user={team?.jobSourcers && team?.jobSourcers[1]?.sourcer}
          onChange={(v) => handleSourcerSelect(v, 1)}
          exclude={excludedSourcerIds}
        >
          <TeamMember
            size={size}
            label="Sourcer 2"
            user={team?.jobSourcers?.[1]?.sourcer}
          />
        </UserPicker>
        {/* interviewer 1 */}
        <UserPicker
          roles={[RoleKey.TechnicalInterviewer]}
          user={team?.jobInterviewers?.[0]?.interviewer}
          onChange={(v) => handleInterviewerSelect(v, 0)}
          exclude={excludedInterviewerIds}
        >
          <TeamMember
            size={size}
            label="Interviewer 1"
            user={team?.jobInterviewers?.[0]?.interviewer}
          />
        </UserPicker>
        {/* interviewer 2 */}
        <UserPicker
          roles={[RoleKey.TechnicalInterviewer]}
          user={team?.jobInterviewers?.[1]?.interviewer}
          onChange={(v) => handleInterviewerSelect(v, 1)}
          exclude={excludedInterviewerIds}
        >
          <TeamMember
            size={size}
            label="Interviewer 2"
            user={team?.jobInterviewers?.[1]?.interviewer}
          />
        </UserPicker>
        {/* expert */}
        <UserPicker
          roles={[RoleKey.Expert, RoleKey.ExternalExpert]}
          user={team?.expert}
          onChange={(v) => handleSelectUser('expert', v)}
        >
          <TeamMember size={size} label="Expert" user={team?.expert} />
        </UserPicker>
        {/* fellow */}
        <UserPicker
          roles={[RoleKey.Fellow]}
          user={team?.fellow}
          onChange={(v) => handleSelectUser('fellow', v)}
        >
          <TeamMember size={size} label="Fellow" user={team?.fellow} />
        </UserPicker>
      </div>
    )
  }
)

JobTeam.displayName = 'Team'
