import { Job } from '@upper/graphql/internal'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@upper/sapphire/ui'
import { JobHealthBadge } from './health-badge'
import { JobHealthInfo } from './health-info'

type Props = { health: Job['health'] }
export const JobHealthIndicator = ({ health }: Props) => {
  return (
    <HoverCard>
      <HoverCardTrigger>
        <JobHealthBadge {...health} />
      </HoverCardTrigger>
      <HoverCardContent
        align="start"
        className="w-56 min-w-0 max-w-none px-1 py-0"
      >
        <JobHealthInfo {...health} />
      </HoverCardContent>
    </HoverCard>
  )
}
