import {
  UserDetailsFragment,
  UserFilters,
  UsersDocument
} from '@upper/graphql/internal'
import { Button, Popover, SelectField } from '@upper/ui'
import { formatName, pick } from '@upper/utils'
import omitDeep from 'omit-deep'
import { useCallback, useState } from 'react'
import { useClient, useQuery } from 'urql'

type Props = {
  children: JSX.Element
  roles: UserFilters['roles']
  user?: UserDetailsFragment
  onChange?: (user: UserDetailsFragment) => void
  exclude?: string[]
}
export const UserPicker = ({
  children,
  roles,
  user,
  exclude,
  onChange,
}: Props) => {
  const urqlClient = useClient()
  const [option, setOption] = useState<Props['user']>()

  const [usersResult] = useQuery({
    query: UsersDocument,
    variables: { filters: { roles } },
  })

  const users =
    usersResult.data?.users?.filter((u) => !(exclude || []).includes(u.id)) ||
    []

  const handleLoadUserOptions = useCallback(
    async (name) => {
      const usersResult = await urqlClient
        .query(UsersDocument, {
          filters: { roles, name },
        })
        .toPromise()
      return (
        usersResult.data?.users
          ?.map((u) => pick(u, 'id', 'firstName', 'lastName'))
          ?.filter((u) => !(exclude || []).includes(u.id)) || []
      )
    },
    [exclude, roles, urqlClient]
  )

  const handleSelect = useCallback((option: any) => {
    setOption(option as UserDetailsFragment)
  }, [])

  const handleSave = useCallback(() => {
    onChange?.(omitDeep({ ...option }, ['__typename']))
  }, [onChange, option])

  const content = (
    <div className="w-60 p-3 space-y-3 bg-white shadow-lg rounded-md overflow-visible">
      <SelectField
        isAsync
        defaultOptions={users}
        defaultValue={user}
        menuWidth={240}
        isClearable
        onChange={handleSelect}
        loadOptions={handleLoadUserOptions}
        getOptionLabel={(o: any) => formatName(o)}
        getOptionValue={(o: any) => o.id}
      />
      <div className="flex">
        <Button fullWidth size="sm" onClick={handleSave}>
          Save
        </Button>
      </div>
    </div>
  )
  return <Popover content={content}>{children}</Popover>
}
