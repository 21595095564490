import { IconProps } from '@upper/ui'

export function EmploymentIcon(props: IconProps) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="15"
        cy="15"
        r="12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle
        r="4"
        transform="matrix(-1 0 0 1 15 15)"
        fill="currentColor"
        fillOpacity="0.2"
      />
    </svg>
  )
}
