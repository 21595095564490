import { JobHealthPerformance } from '@upper/graphql/internal';
import chroma from 'chroma-js';
import { JOB_HEALTH_COLORS } from '../const/job';

export const jobHealthColorForValue = (
  value: JobHealthPerformance
): { background: string; color: string } => {
  let background = JOB_HEALTH_COLORS.gray
  switch (value) {
    case JobHealthPerformance.Bad:
      background = JOB_HEALTH_COLORS.red
      break
    case JobHealthPerformance.Mediocre:
      background = JOB_HEALTH_COLORS.yellow
      break
    case JobHealthPerformance.Good:
      background = JOB_HEALTH_COLORS.green
      break
    default:
      background = JOB_HEALTH_COLORS.gray
      break;
  }

  const color = chroma(background).darken(1.6).hex()
  return { background, color }
}
