import { JobHealthBaseFragment } from '@upper/graphql/internal'
import { classNames } from '@upper/ui'
import { HeartIcon } from 'lucide-react'
import { forwardRef } from 'react'
import { jobHealthColorForValue } from '../../utils/job'

type JobHealthBadgeProps = Omit<JobHealthBaseFragment, '__typename'>
export const JobHealthBadge = forwardRef<any, JobHealthBadgeProps>(
  ({ performance }, ref) => {
    const colors = jobHealthColorForValue(performance)

    return (
      <div
        ref={ref}
        className={classNames(
          'inline-flex h-8 w-8 flex-none cursor-help items-center justify-center rounded-full p-1 font-mono text-xs'
        )}
        style={{ ...colors }}
      >
        <HeartIcon size={18} strokeWidth={2.4} style={{ ...colors }} />
      </div>
    )
  }
)
JobHealthBadge.displayName = 'JobHealthBadge'
